import { graphql } from 'gatsby'
import React from 'react'
import BlogList from '../components/blogList'
import Layout from '../components/layout'
import Profile from '../components/profile'
import SEO from '../components/seo'

const BlogPage = ({
	data: {
		allMarkdownRemark: { edges },
		file: { childImageSharp }
	}
}) => {
	return (
		<Layout>
			<SEO
				keywords={[`anshumanv`, `gatsby`, `blog`, `anshumanv's blog`]}
				title="Anshuman's Blog"
				description="Log of my thoughts and learnings."
			/>
			<section className="flex flex-col h-full flex-grow">
				<h1 className="text-4xl my-5 text-center font-black font-mont">Blog</h1>
				<section>
					<div className="my-5">
						Log of some of my thoughts about Tech & Life. Anime and Travelling
						will be in a separate blog. 😛
					</div>
					<BlogList blogs={edges} />
				</section>
			</section>
			<Profile image={childImageSharp} />
		</Layout>
	)
}

export const query = graphql`
	query {
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			totalCount
			edges {
				node {
					id
					timeToRead
					frontmatter {
						title
						path
						date(formatString: "DD MMMM, YYYY")
					}
				}
			}
		}
		file(relativePath: { eq: "me.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default BlogPage
