import { Link } from 'gatsby'
import React from 'react'

const BlogList = ({ blogs }) => {
	return (
		<ul className="flex flex-col text-white">
			{blogs.map(blog => (
				<li key={blog.node.id} className="my-5">
					<Link to={blog.node.frontmatter.path}>
						<h2 className="text-3xl font-black font-mont leading-loose text-blue-300">
							{blog.node.frontmatter.title}
						</h2>
						<span className="text-sm font-light tracking-wider">
							📆 {blog.node.frontmatter.date} • ⏲️ {blog.node.timeToRead} min
							read.
						</span>
						<span>{blog.node.frontmatter.tags}</span>
					</Link>
				</li>
			))}
		</ul>
	)
}

export default BlogList
